<template>
  <b-card class="border-top-primary border-3 border-table-radius px-0">
  <div>
    <div class="d-flex justify-content-between mb-1">
      <div />
      <b-button
        variant="success"
        @click="openCreateModal(null)"
      >
        <feather-icon
          icon="PlusIcon"
          size="15"
          class="mr-50 text-white"
        />CREATE
      </b-button>
    </div>
    <filter-slot
      :filter="[]"
      :filter-principal="filterPrincipal"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      @reload="$refs['categoryCompanies'].refresh()"
    >
      <template #table>
        <b-table
            small
          ref="categoryCompanies"
          no-border-collapse
          :fields="fields"
          show-empty
          no-provider-filtering
          sticky-header="50vh"
          primary-key="id"
          responsive="sm"
          :items="categoriesProvider"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
          :filter="searchInput"
          :busy.sync="isBusy"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <template #cell(name)="data">
            <span
              :style="`margin-left: ${
                data.item.id === data.item.parent_account ? 0 : data.item.type * 15
              }px`"
            >{{ data.item.name }}</span>
          </template>
          <template #cell(parent_account)="data">
            <span v-if="data.item.id === data.item.parent_account">-</span>
            <span v-else>Sub account of {{ data.item.parent_account_text }}</span>
          </template>
          <template #cell(total_soles_sum)="data">
            <span>S/. {{formatPrice(data.item.total_soles_sum) }}</span>
          </template>
          <template #cell(total_dolares_sum)="data">
            <span>$ {{formatPrice(data.item.total_dolares_sum) }}</span>
          </template>
          <template #cell(created_by)="data">
            {{ data.item.created_by }}<br> ({{ data.item.created_at | myGlobalWithHour }})
          </template>
          <template #cell(actions)="data">
            <feather-icon
              size="18"
              class="mr-half1 cursor-pointer  text-success"
              icon="ApertureIcon"
              @click="openReportSidebar(data.item, data.index)"
            />
            <feather-icon
              size="18"
              class="mr-half1 cursor-pointer  text-warning"
              icon="EditIcon"
              @click="openEditModal(data.item.id, data.item)"
            />
            <feather-icon
              size="18"
              class="mr-half1 cursor-pointer  text-primary"
              icon="EyeIcon"
              @click="openReportWatchModal(data.item.id,data.item.total_dolares_sum,data.item.total_soles_sum )"
            />
            <feather-icon
              size="18"
              class="mr-half1 cursor-pointer  text-danger"
              icon="TrashIcon"
              @click="deleteCategory(data.item.id)"
            />
          </template>
        </b-table>
        <create-category-com-modal
          v-if="openCreateModalController"
          :companyId="idCompany"
          @close="closeCreateModal"
          @reload="$refs['categoryCompanies'].refresh()"
        />
        <edit-category-com-modal
          v-if="openEditModalController"
          :categoryId="idCategory"
          :item="selectedItem"
          :companyId="idCompany"
          @close="closeEditModal"
          @reload="$refs['categoryCompanies'].refresh()"
        />
        <category-report-modal
          v-if="openReportWatchModalController"
          @close="closeReportWatchModal"
          :categoryId="idCategory"
          :totalDolares="totalSolesSum"
          :totalSoles="totalDolaresSum"
        />
        <category-report-sidebar
          v-if="reportSidebarOn"
          :data="items"
          :item="selectedItem"
          @close="closeReportSidebar"
        />
      </template>
    </filter-slot>
  </div>
  </b-card>
</template>

<script>
import CategoriesComService from '@/views/management/views/companies/dashboard/categories-module/categories-com.service'
import CreateCategoryComModal from '@/views/management/views/companies/dashboard/categories-module/modals/CreateCategoryComModal'
import EditCategoryComModal from '@/views/management/views/companies/dashboard/categories-module/modals/EditCategoryComModal'
import CategoriesService from '@/views/management/views/categories/categories.service'
import CategoryReportSidebar from '@/views/management/views/companies/dashboard/categories-module/sidebars/CategoryReportSidebar'
import CategoryReportModal from '@/views/management/views/companies/dashboard/categories-module/modals/CategoryReportModal'
import CompaniesService from '@/views/management/views/companies/companies.service'
import { mapGetters } from 'vuex'

export default {
  components: {
    CreateCategoryComModal,
    EditCategoryComModal,
    CategoryReportModal,
    CategoryReportSidebar,
  },
  data() {
    return {
      filterPrincipal: {
        type: 'input',
        inputType: 'text',
        placeholder: 'Name of category...',
        model: '',
      },
      startPage: 0,
      toPage: 0,
      openCreateModalController: false,
      openEditModalController: false,
      openReportWatchModalController: false,
      reportSidebarOn: false,
      totalSolesSum: 0,
      totalDolaresSum: 0,
      fields: [
        {
          key: 'name',
          label: 'Name',
          thStyle: {
            margin: '0px',
            width: '300px',
          },
        },
        {
          key: 'account_type_text',
          label: 'Type',
        },
        {
          key: 'detail_type_text',
          label: 'Detail',
        },
        {
          key: 'description',
          label: 'Description',
        },
        {
          key: 'parent_account',
          label: 'Sub Account',
        },
        {
          key: 'total_soles_sum',
          label: 'Total expenses S/',
          thStyle: {
            width: '150px',
          },
        },
        {
          key: 'total_dolares_sum',
          label: 'Total expenses $',
          thStyle: {
            width: '140px',
          },
        },
        {
          key: 'created_by',
          label: 'Created By',
        },
        {
          key: 'actions',
          label: 'Actions',
          thStyle: {
            width: '150px',
          },
        },
      ],
      totalRows: 1,
      paginate: {
        perPage: 100,
        currentPage: 1,
      },
      sortBy: 'name',
      sortDesc: true,
      searchInput: '',
      isBusy: false,
      idCompany: '',
      items: [],
      idCategory: null,
      selectedItem: [],
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
      token: 'auth/token',
    }),
  },
  async created() {
    this.idCompany = this.$route.params.id
  },
  methods: {
    formatPrice(value) {
      const val = (value / 1).toFixed(2).replace(',', '.')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    async categoriesProvider(ctx) {
      this.idCompany = this.$route.params.id
      try {
        const params = {
          orderby: 1,
          per_page: ctx.perPage,
          page: ctx.currentPage,
          order: ctx.sortDesc === 1 ? 'desc' : 'asc',
          campo: this.filterPrincipal.model,
          company_id: this.idCompany,
        }
        const data = await CompaniesService.getCategoriesByCompany(params)
        this.items = data.data
        this.startPage = data.from
        this.paginate.currentPage = data.current_page
        this.paginate.perPage = data.per_page
        this.nextPage = this.startPage + 1
        this.endPage = data.last_page
        this.totalRows = data.total
        this.toPage = data.to
        return this.items || []
      } catch (e) {
        this.showErrorSwal(e)
      }
    },
    async deleteCategory(id) {
      try {
        const response = await this.showConfirmSwal()
        if (response.isConfirmed) {
          const data = await CategoriesService.deleteCategory({
            deleted_by: this.currentUser.user_id,
            category_id: id,
          })
          if (data.status === 200) {
            this.showSuccessSwal('Category has been deleted successfully')
          }
          this.$refs.categoryCompanies.refresh()
        }
      } catch (e) {
        this.showErrorSwal(e)
        return []
      }
    },
    openCreateModal() {
      this.openCreateModalController = true
    },
    closeCreateModal() {
      this.openCreateModalController = false
    },
    closeReportSidebar() {
      this.reportSidebarOn = false
      this.selectedItem = null
    },
    openReportSidebar(item, index) {
      this.reportSidebarOn = true
      this.selectedItem = item
      this.selectedItem.index = index
    },
    openEditModal(id, item) {
      this.idCategory = id
      this.openEditModalController = true
      this.selectedItem = item
    },
    closeEditModal() {
      this.idCategory = null
      this.openEditModalController = false
      this.selectedItem = null
    },
    openReportWatchModal(id, totalSoles, totalDolares) {
      this.idCategory = id
      this.openReportWatchModalController = true
      this.totalSolesSum = totalSoles
      this.totalDolaresSum = totalDolares
    },
    closeReportWatchModal() {
      this.idCategory = null
      this.openReportWatchModalController = false
    },
  },
}
</script>

<style scoped>
.mr-half1{
  margin-right: 0.3rem;
}

</style>
