<template>
  <b-modal
    v-model="onControl"
    title="Edit Category"
    title-tag="h3"
    modal-class="modal-primary"
    size="lg"
    :hide-footer="!isEditing"
    no-close-on-backdrop
    @hidden="close"
  >
    <div class="d-block">
      <validation-observer ref="form">
        <b-container>
          <validation-provider
            v-slot="{ errors, valid }"
            name="account_type"
            rules="required"
          >
            <b-form-group
              label="Account type:"
              label-for="input-1"
            >
              <div :class="isEditing ? 'g-account-type' : '' ">
                <b-skeleton
                  v-if="!finishedLoading"
                  type="input"
                />
                <v-select
                  v-else
                  v-model="category.accountType"
                  :disabled="!isEditing"
                  label="description"
                  :options="accountTypes"
                  :reduce="description => description.id"
                  @input="onChangeAccountType"
                />
                <div
                  v-if="isEditing"
                  class="align-self-center ml-1"
                >
                  <feather-icon
                    v-if="!showPlusAccount"
                    class="pointer text-primary"
                    icon="PlusCircleIcon"
                    size="18"
                    @click="showAccountFields"
                  />
                  <feather-icon
                    v-if="showPlusAccount"
                    class="pointer text-primary"
                    icon="MinusCircleIcon"
                    size="18"
                    @click="hideAccountFields"
                  />
                </div>
              </div>
              <span
                v-if="errors[0]"
                class="text-danger"
              >
                Account type {{ errors[0] }}</span>
            </b-form-group>
          </validation-provider>
          <validation-observer
            v-if="showPlusAccount"
            ref="accountForm"
          >
            <validation-provider
              v-slot="{ errors, valid }"
              name="account_type_description"
              :rules="rules"
            >
              <b-form-group
                label="Account type Description:"
                label-for="input-7"
              >
                <div style="display: grid; grid-template-columns: 13fr 4fr">
                  <b-form-input
                    v-model="accountTypeObject.description"
                    type="text"
                  />
                  <div class="d-flex align-items-center ml-1">
                    <b-button
                      variant="outline-info"
                      @click="saveAccountType(1)"
                    >
                      Save Account Type
                    </b-button>
                  </div>
                </div>
                <span
                  v-if="errors[0]"
                  class="text-danger"
                >
                  Account type Description {{ errors[0] }}</span>
              </b-form-group>
            </validation-provider>
          </validation-observer>
          <validation-provider
            v-slot="{ errors, valid }"
            name="detail_type"
            rules="required"
          >
            <b-form-group
              label="Detail type:"
              label-for="input-2"
            >
              <div
                :class="isEditing ? 'g-detail-type' : '' "
              >
                <b-skeleton
                  v-if="!finishedLoading"
                  type="input"
                />
                <v-select
                  v-if="!isSecondTime && finishedLoading"
                  v-model="category.detailType"
                  :disabled="!isEditing"
                  label="description"
                  :options="accountTypesDetail"
                  :reduce="description => description.id"
                />
                <v-select
                  v-if="isSecondTime && finishedLoading"
                  v-model="categoryObject"
                  :disabled="!isEditing"
                  label="description"
                  :options="accountTypesDetail"
                  @input="onChangeDetailTypeObject"
                />
                <div
                  v-if="isEditing"
                  class="align-self-center ml-1"
                >
                  <feather-icon
                    v-if="!showPlusDetail"
                    class="pointer text-primary"
                    icon="PlusCircleIcon"
                    size="18"
                    @click="showDetailFields"
                  />
                  <feather-icon
                    v-if="showPlusDetail"
                    class="pointer text-primary"
                    icon="MinusCircleIcon"
                    size="18"
                    @click="hideDetailFields"
                  />
                </div>
              </div>
              <span
                v-if="errors[0]"
                class="text-danger"
              >
                Detail type {{ errors[0] }}</span>
            </b-form-group>
          </validation-provider>
          <validation-observer
            v-if="showPlusDetail"
            ref="accountTypeDetailForm"
            style="display: grid; grid-template-columns: 7fr 10fr"
          >
            <validation-provider
              v-slot="{ errors, valid }"
              name="account_type_detail"
              :rules="rules"
            >
              <b-form-group
                label="Account type Detail:"
                label-for="input-9"
              >
                <b-form-input
                  v-model="accountTypeDetailObject.description"
                  type="text"
                  required
                />
                <span
                  v-if="errors[0]"
                  class="text-danger"
                >
                  Account type Detail {{ errors[0] }}</span>
              </b-form-group>
            </validation-provider>
            <validation-provider
              v-slot="{ errors, valid }"
              name="account_type_mini_form"
              :rules="rules"
            >
              <b-form-group
                label="Account type:"
                label-for="input-10"
                class="ml-1"
              >
                <div style="display: grid; grid-template-columns: 7fr 3fr">
                  <v-select
                    v-model="accountTypeDetailObject.parent_id"
                    label="description"
                    :options="accountTypes"
                    :reduce="description => description.id"
                  />
                  <div class="d-flex align-items-center ml-1">
                    <b-button
                      variant="outline-info"
                      @click="saveAccountType(2)"
                    >
                      Save Detail
                    </b-button>
                  </div>
                </div>
                <span
                  v-if="errors[0]"
                  class="text-danger"
                >
                  Account type {{ errors[0] }}</span>
              </b-form-group>
            </validation-provider>

          </validation-observer>
          <b-form-group
            label="Name:"
            label-for="input-3"
          >
            <b-skeleton
              v-if="!finishedLoading"
              type="input"
            />
            <b-form-input
              v-else
              v-model="category.name"
              type="text"
              required
              disabled
            />
          </b-form-group>
          <b-form-group
            label="Choose parent account:"
            label-for="input-13"
          >
            <b-skeleton
              v-if="!finishedLoading"
              type="input"
            />
            <v-select
              v-else
              v-model="category.parentAccount"
              label="name"
              :options="parentAccounts"
            >
              <template
                v-if="category.parentAccount.name!==''"
                #selected-option="{ name, parent_name, type }"
              >
                <span v-if="type===1">{{ name }}</span>
                <span v-else>{{ parent_name + ' : ' + name }}</span>
              </template>
              <template #option="{ name, id, parent_account, type, isChild, account_type_text }">
                <span class="d-flex justify-content-between">
                  <span :style="`margin-left: ${id == parent_account?0:type*15}px`">
                    <feather-icon
                      v-if="isChild===1 || id == parent_account"
                      icon="ChevronDownIcon"
                      size="10"
                    />
                    <span v-else>&nbsp;&nbsp;&nbsp;&nbsp; </span>
                    {{ name }}</span>
                  <span v-if="id == parent_account">{{ account_type_text }}</span>
                </span>
              </template>
            </v-select>
          </b-form-group>
          <b-form-group
            label="Description:"
            label-for="input-4"
          >
            <b-skeleton-wrapper :loading="!finishedLoading">
              <template #loading>
                <b-skeleton
                  height="60px"
                  type="input"
                />
              </template>
              <b-form-textarea
                id="textarea-default"
                v-model="category.description"
                :disabled="!isEditing"
              />
            </b-skeleton-wrapper>
          </b-form-group>
        </b-container>
      </validation-observer>
    </div>
    <template #modal-footer="{ cancel }">
      <button-cancel @click="cancel()" />
      <button-save @click="saveCategory" />
    </template>
  </b-modal>
</template>

<script>
import vSelect from 'vue-select'
import buttonSave from '@/views/commons/utilities/ButtonSave.vue'
import buttonCancel from '@/views/commons/utilities/ButtonCancel.vue'
import CategoriesService from '@/views/management/views/categories/categories.service'
import { mapGetters } from 'vuex'

export default {
  components: {
    vSelect,
    buttonSave,
    buttonCancel,
  },
  props: {
    item: null,
    companyId: null,
  },
  data() {
    return {
      onControl: false,
      selectedText: '',
      accountTypes: [],
      accountTypesDetail: [],
      parentAccounts: [],
      category: {
        accountType: 0,
        detailType: '',
        name: '',
        description: '',
        isSubAccount: false,
        parentAccount: {
          name: '',
          id: null,
          type: '',
        },
      },
      accountTypeObject: {
        description: null,
        parent_id: null,
      },
      accountTypeDetailObject: {
        description: '',
        parent_id: '',
      },
      categoryObject: {
        description: null,
        id: null,
      },
      selected: '',
      showPlusAccount: false,
      showPlusDetail: false,
      isEditing: true,
      title: '',
      finishedLoading: false,
      isSecondTime: false,
      rules: 'required',
      sum: 0,
    }
  },
  async created() {
    this.finishedLoading = false

    this.category.description = this.item.description
    this.category.name = this.item.name
    this.category.isSubAccount = this.item.is_sub_account
    this.category.parentAccount.id = this.item.parent_account
    this.category.parentAccount.name = this.item.parent_account_text
    this.category.parentAccount.type = this.item.type
    await this.getParentAccounts()
    this.category.parentAccount.type = this.item.type
    this.onControl = true
    await this.getAccountTypes()
    this.category.accountType = this.item.account_type
    await this.getAccountTypesDetail(this.category.accountType)
    this.category.detailType = this.item.detail_type
    this.finishedLoading = true
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
      token: 'auth/token',
    }),
  },
  methods: {
    close() {
      this.$emit('close')
    },
    async getAccountTypesDetail(parentId) {
      this.accountTypesDetail = await CategoriesService.getAccountTypesDetail({ parent_id: parentId })
    },
    async getAccountTypes() {
      this.accountTypes = await CategoriesService.getAccountTypes()
    },
    async saveAccountType(type) {
      if (type === 1) {
        try {
          const result = await this.$refs.accountForm.validate()
          if (result) {
            const response = await this.showConfirmSwal()
            if (response.isConfirmed) {
              this.accountTypeObject.parent_id = null
              const data = await CategoriesService.saveAccountType({
                description: this.accountTypeObject.description,
                parent_id: this.accountTypeObject.parent_id,
              })

              await this.getAccountTypes()
              if (data.status === 200) {
                this.showSuccessSwal(`Account Type ${this.accountTypeObject.description} has been added successfully`)
                this.showPlusAccount = false
                this.accountTypeObject.description = null
                this.accountTypeObject.parent_id = null
              }
            }
          }
        } catch (e) {
          this.showErrorSwal(e)
        }
      } else {
        try {
          const result = await this.$refs.accountTypeDetailForm.validate()
          if (result) {
            const response = await this.showConfirmSwal()
            if (response.isConfirmed) {
              const data = await CategoriesService.saveAccountType({
                description: this.accountTypeDetailObject.description,
                parent_id: this.accountTypeDetailObject.parent_id,
              })
              if (this.accountTypeDetailObject.parent_id === this.category.accountType) {
                await this.getAccountTypesDetail(this.accountTypeDetailObject.parent_id)
              }

              if (data.status === 200) {
                this.showSuccessSwal(`Account Type Detail ${this.accountTypeDetailObject.description} has been added successfully`)
                this.showPlusDetail = false
                this.accountTypeDetailObject.description = null
                this.accountTypeDetailObject.parent_id = null
              }
            }
          }
        } catch (e) {
          this.showErrorSwal(e)
        }
      }
    },
    async saveCategory() {
      try {
        this.rules = ''
        this.showPlusAccount = false
        this.showPlusDetail = false
        const result = await this.$refs.form.validate()
        if (result) {
          const response = await this.showConfirmSwal()
          if (response.isConfirmed) {
            const data = await CategoriesService.saveCategory({
              account_type: this.category.accountType,
              detail_type: this.category.detailType,
              name: this.category.name,
              description: this.category.description,
              is_sub_account: this.category.isSubAccount,
              parent_account: this.category.parentAccount.id,
              t_type: this.category.parentAccount.type + this.sum,
              created_by: this.currentUser.user_id,
              t_category_id: this.item.id,
              company_id: Number(this.companyId),

            })
            if (data.status === 200) {
              this.showSuccessSwal('Category has been updated successfully')
              this.close()
              this.rules = 'required'
              this.$emit('reload')
            }
          }
        }
      } catch (e) {
        this.showErrorSwal(e)
      }
    },
    showAccountFields() {
      this.showPlusAccount = true
    },
    hideAccountFields() {
      this.showPlusAccount = false
      this.accountTypeObject.description = ''
    },
    showDetailFields() {
      this.showPlusDetail = true
    },
    hideDetailFields() {
      this.showPlusDetail = false
      this.accountTypeDetailObject.description = ''
      this.accountTypeDetailObject.parent_id = ''
    },
    onChangeAccountType() {
      this.getAccountTypesDetail(this.category.accountType)
      this.category.detailType = ''
      this.category.name = ''
      this.categoryObject = ''
      this.isSecondTime = true
    },
    onChangeDetailTypeObject() {
      this.category.name = this.categoryObject.description
      this.category.detailType = this.categoryObject.id
    },
    async getParentAccounts() {
      const data = await CategoriesService.getParentAccounts({
        company_id: this.companyId,
      })
      this.parentAccounts = data

      if (this.category.parentAccount.type === 1) {
        for (let i = 0; i < this.parentAccounts.length - 1; i++) {
          if ((this.parentAccounts[i].type < this.parentAccounts[i + 1].type)) {
            this.parentAccounts[i].isChild = 1
          }
        }
      } else {
        for (let i = 0; i < this.parentAccounts.length - 1; i++) {
          if ((this.parentAccounts[i].type < this.parentAccounts[i + 1].type)) {
            this.parentAccounts[i].isChild = 1
          }
          if (this.parentAccounts[i].id === this.category.parentAccount.id) {
            this.category.parentAccount = this.parentAccounts[i]
          }
        }
      }
    },
  },
  watch: {
    'category.parentAccount.id': function () {
      if (this.category.parentAccount.id !== this.item.parent_account) {
        this.sum = 1
      } else {
        this.sum = 0
      }
    }
  }
}
</script>

<style scoped>
.bigger {
  width: 2em;
  height: 2em;
}
.g-detail-type{
  display: grid;
  grid-template-columns: 12fr .5fr
}
.g-account-type{
  display: grid;
  grid-template-columns: 12fr .5fr
}
</style>
