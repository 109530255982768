<template>
  <b-modal
    v-model="onControl"
    title="Category Report"
    title-tag="h3"
    modal-class="modal-primary"
    size="lg"
    hide-footer
    @hidden="close"
  >
    <b-nav
      pills
      class="m-0"
    >
      <b-nav-item
        exact
        :active="currency===1"
        exact-active-class="active"
        :link-classes="['px-3',bgTabsNavs]"
        @click="currency=1;$refs['refCurrencyTable'].refresh()"
      >S/</b-nav-item>
      <b-nav-item
        exact
        :active="currency===2"
        exact-active-class="active"
        :link-classes="['px-3',bgTabsNavs]"
        @click="currency=2;$refs['refCurrencyTable'].refresh()"
      >$</b-nav-item>
    </b-nav>
    <filter-slot
      :filter="[]"
      :filter-principal="filterPrincipal"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      @reload="$refs['refCurrencyTable'].refresh()"
    >
      <template #table>
        <b-table
          ref="refCurrencyTable"
          no-border-collapse
          class="position-relative"
          :fields="fields"
          show-empty
          no-provider-filtering
          sticky-header="50vh"
          primary-key="id"
          responsive="sm"
          :items="categoriesReportProvider"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
          :filter="searchInput"
          :busy="isBusy"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <template #cell(payment_date)="data">
            {{data.item.payment_date | myGlobalWithHour  }}
          </template>
          <template #cell(total)="data">
            <money
                :class="isDarkSkin? 'text-white':''"
                :value="data.item.total"
                v-bind="{
                      decimal: '.',
                      thousands: ',',
                      prefix: currency==1?'S/ ':'$ ',
                      precision: 2,
                      masked: false,
                    }"
                style="border: none"
                disabled
            ></money>
          </template>
          <template #custom-foot>
            <b-tr :class="isDarkSkin? 'bg-category-modal' : 'bg-secondary'">
              <b-td :class="isDarkSkin? 'bg-category-modal' : 'bg-secondary'"/>
              <b-td :class="isDarkSkin? 'bg-category-modal' : 'bg-secondary'"/>
              <b-td :class="isDarkSkin? 'bg-category-modal' : 'bg-secondary'"/>
              <b-td class="text-white font-weight-bolder font-small-3"  >Subtotal</b-td>
              <b-td :class="isDarkSkin? 'bg-category-modal' : 'bg-secondary'">
                <b-badge variant="light" :class="isDarkSkin? 'text-black' : 'text-dark'" >
                  <money
                    :value="subTotal"
                    v-bind="{
                      decimal: '.',
                      thousands: ',',
                      prefix: currency==1?'S/ ':'$ ',
                      precision: 2,
                      masked: false,
                    }"
                    style="border: none"
                    disabled
                  ></money>
                </b-badge>
              </b-td>
            </b-tr>
            <b-tr :class="isDarkSkin? 'bg-category-modal' : 'bg-secondary'">
              <b-td :class="isDarkSkin? 'bg-category-modal' : 'bg-secondary'"/>
              <b-td :class="isDarkSkin? 'bg-category-modal' : 'bg-secondary'"/>
              <b-td :class="isDarkSkin? 'bg-category-modal' : 'bg-secondary'"/>
              <b-td class="text-white font-weight-bolder font-small-3">Total</b-td>
              <b-td :class="isDarkSkin? 'bg-category-modal' : 'bg-secondary'">
                <b-badge variant="light" :class="isDarkSkin? 'text-black' : 'text-dark'">
                  <money
                    :value="totalMoney"
                      v-bind="{
                      decimal: '.',
                      thousands: ',',
                      prefix: currency==1?'S/ ':'$ ',
                      precision: 2,
                      masked: false,
                    }"
                      style="border: none"
                      disabled
                  ></money>
                </b-badge>
              </b-td>
            </b-tr>
          </template>
        </b-table>
      </template>
    </filter-slot>
  </b-modal>
</template>

<script>
import CategoriesComService from '@/views/management/views/companies-old/dashboard/categories-module/categories-com.service'
import CompaniesService from '@/views/management/views/companies-old/companies.service'
export default {
  props: {
    categoryId: null,
    totalDolares: null,
    totalSoles: null,
  },
  data() {
    return {
      onControl: false,
      filterPrincipal: {
        type: 'input',
        inputType: 'text',
        placeholder: '# of Expense...',
        model: '',
      },
      totalRows: 0,
      paginate: {
        perPage: 10,
        currentPage: 1,
      },
      startPage: 0,
      toPage: 0,
      searchInput: '',
      isBusy: false,
      totalMoney: 0,
      fields: [
        {
          key: 'doc_no',
          label: '# Expense',
        },
        {
          key: 'currency',
          label: 'Currency',
        },
        {
          key: 'bank_name',
          label: 'Bank',
        },
        {
          key: 'payment_date',
          label: 'Date',
        },
        {
          key: 'total',
          label: 'Total',
        },
      ],
      items: [],
      currency: 1,
    }
  },
  watch: {
    currency(newVal) {
      this.totalMoney = newVal === 1 ? this.totalSoles : this.totalDolares
      if (this.totalMoney === null) this.totalMoney = 0
    },
  },
  async created() {
    this.onControl = true
  },
  methods: {
    close() {
      this.$emit('close')
    },
    async categoriesReportProvider(ctx) {
      try {
        this.items = []
        const data = await CompaniesService.getCategoryReport({
          campo: this.filterPrincipal.model,
          category_id: this.categoryId,
          page: ctx.currentPage,
          currency: this.currency,
          order: 'desc',
          per_page: ctx.perPage,
          orderby: 5,
        })
        if (data.status === 200) {
          this.items = data.data.data
          this.startPage = data.data.from ===null? 0 : data.data.from
          this.paginate.currentPage = data.data.current_page
          this.paginate.perPage = data.data.per_page
          this.nextPage = data.data.startPage + 1
          this.endPage = data.data.last_page
          this.totalRows = data.data.total
          this.toPage = data.data.to  ===null? 0 : data.data.to
          return this.items || []
        }
      } catch (e) {
        this.showErrorSwal(e)
      }
    },
  },
  computed: {
    subTotal() {
      let sum = 0;
      for (let i = 0; i < this.items.length; i++) {
        sum += Number(this.items[i].total);
      }
      return sum.toFixed(2);
    },
  },
}
</script>

<style>
.bg-category-modal{
  background-color: #282828 !important;
}
.text-black .v-money{
  color: black !important;
}
</style>
