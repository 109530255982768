<template>
  <b-sidebar
    id="sidebar-report"
    v-model="ownControl"
    left
    bg-variant="white"
    header-class="text-primary text-center"
    shadow
    width="95%"
    @hidden="closeModal"
  >
    <template #header="{hide}">
      <div
        class="d-flex justify-content-between mt-2"
        style="width: 100%; height: 100%; border-bottom: 1px solid #007bff; "
      >
        <span class="text-primary k-font-weight-bold">{{ name }}</span>
        <feather-icon
          class="text-primary cursor-pointer"
          icon="XIcon"
          size="20"
          @click="hide"
        />
      </div>
    </template>
    <template #default>
      <b-card
        class="my-2 mx-1"
        body-class="card2"
      >
        <div class="mx-3 my-1 d-flex justify-content-between">
          <div class="header-sidebar-report">
            <v-select
              v-model="filters.range"
              v-b-tooltip.hover.top="'Range'"
              :options="optionDates"
              :reduce="(option) => option.id"
              @input="onChangeDate"
            />
            <b-form-datepicker
              v-model="filters.from"
              v-b-tooltip.hover.top="'From'"
              class="ml-half-1"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              locale="en"
              @input="getTransactions"
            />
            <b-form-datepicker
              v-model="filters.to"
              v-b-tooltip.hover.top="'To'"
              class="mxb"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              locale="en"
              @input="getTransactions"
            />
          </div>
          <b-dropdown
            no-caret
            text="Export"
            variant="light"
          >
            <b-dropdown-item @click="exportExcel">
              Export to Excel
            </b-dropdown-item>
            <b-dropdown-item @click="exportPdf">
              Export to PDF
            </b-dropdown-item>
          </b-dropdown>
        </div>
        <b-table
          id="idReportTable"
          ref="refReportTable"
          no-border-collapse
          class="position-relative"
          :fields="fields"
          show-empty
          no-provider-filtering
          responsive
          :items="transactions.filter(transaction => transaction.show)"
          :busy="isBusy"
          :tbody-tr-class="returnClass"
          @row-clicked="clickedRow"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <template
            #cell(date)="data"
            class="p-0"
          >
            <span
              :style="'margin-left:'+ data.item.level * 10 + 'px'"
              :class="data.item.bold? 'k-font-weight-bold': ''"
            >
              <tabler-icon
                v-if="data.item.action && data.item.pather"
                icon="CaretRightIcon"
              />
              <tabler-icon
                v-else-if="data.item.action == false && data.item.pather"
                icon="CaretDownIcon"
              />
              <span
                v-else
                style="margin-left: 7px"
              />
              {{ data.item.date }}
            </span>
          </template>
          <template #cell(balance_d)="data">
            <span :class="data.item.bold? 'k-font-weight-bold': ''">{{ data.item.balance_d }}</span>
          </template>
          <template #cell(balance_s)="data">
            <span :class="data.item.bold? 'k-font-weight-bold': ''">{{ data.item.balance_s }}</span>
          </template>
        </b-table>
      </b-card>
    </template>
  </b-sidebar>
</template>

<script>
import CompaniesService from '@/views/management/views/companies-old/companies.service'
import moment from 'moment'
import vSelect from 'vue-select'

export default {
  components: {
    vSelect,
  },
  props: {
    data: null,
    item: null,
  },
  data() {
    return {
      ownControl: false,
      fields: [
        {
          key: 'date',
          label: 'Date',
          thStyle: {
            width: '500px',
            textAlign: 'center',
          },
        },
        {
          key: 'type',
          label: 'Transaction Type',
        },
        {
          key: 'name',
          label: 'Name',
        },
        {
          key: 'memo',
          label: 'Memo/Description',
        },
        {
          key: 'amount_d',
          label: '$ Amount',
          thStyle: {
            width: '145px',
          },
        },
        {
          key: 'balance_d',
          label: '$ BL',
          thStyle: {
            width: '145px',
          },

        },
        {
          key: 'amount_s',
          label: 'S/. Amount',
          thStyle: {
            width: '145px',
          },
        },
        {
          key: 'balance_s',
          label: 'S/. BL',
          thStyle: {
            width: '145px',
          },

        },
      ],
      items: [],
      optionDates: [{ id: 1, label: 'This Month' }, { id: 2, label: 'This Month-to-date' },
        { id: 3, label: 'This Quarter' }, { id: 4, label: 'This Quarter-to-date' }],
      isBusy: false,
      transactions: [],
      filters: {
        from: '',
        to: '',
        range: 1,
      },
      name: '',
    }
  },
  async mounted() {
    this.ownControl = true
    this.name = this.item.date
  },
  async created() {
    this.filters.from = moment().startOf('month').format('YYYY-MM-DD')
    this.filters.to = moment().endOf('month').format('YYYY-MM-DD')
    this.addPreloader()
    await this.getTransactions()
    this.removePreloader()

    this.name = this.item.name
  },
  methods: {
    closeModal() {
      this.$emit('close')
    },
    returnClass(item) {
      const light = this.isDarkSkin ? 'table-light2 ' : 'table-light '
      const danger = this.isDarkSkin ? 'table-danger2 ' : 'table-danger '
      if (item !== null) {
        if (item.current_phater) {
          return `${light}border-white cursor-pointer`
        } if (item.payments > 0 && item.pather) {
          return `${light}font-size-medium-4 border-white cursor-pointer`
        } if (item.payments < 1 && item.pather) {
          return `${danger}border-white cursor-pointer`
        } if (item.footer) {
          return 'table-dark k-font-weight-bold text-uppercase bg-so-dark border-light'
        }
      }
    },
    async getTransactions() {
      try {
        this.isBusy = true
        const data = await CompaniesService.getTransactions({
          item: this.item,
          data: this.data,
          from: this.filters.from,
          to: this.filters.to,
        })
        if (data.status === 200) {
          this.isBusy = false
          this.transactions = data.data
        }
      } catch (e) {
        this.isBusy = false
        this.showErrorSwal(e)
      }
    },
    clickedRow(item, index, event) {
      this.showHide(item, index)
    },
    showHide(item, index) {
      if (item.pather) {
        let fin = false
        this.transactions.forEach((element, i) => {
          if (i > index && fin === false) {
            if (element.level > item.level) {
              element.show = item.action
              element.action = !item.action
            }
            if (element.level === item.level && element.pather) {
              fin = true
            }
          }
        })
        item.action = !item.action
      }
    },
    async onChangeDate() {
      switch (this.filters.range) {
        case 1:
          this.filters.from = moment().startOf('month').format('YYYY-MM-DD')
          this.filters.to = moment().endOf('month').format('YYYY-MM-DD')
          break
        case 2:
          this.filters.from = moment().startOf('month').format('YYYY-MM-DD')
          this.filters.to = moment().format('YYYY-MM-DD')
          break
        case 3:
          this.filters.from = moment().subtract(3, 'month').startOf('month').format('YYYY-MM-DD')
          this.filters.to = moment().endOf('month').format('YYYY-MM-DD')
          break
        case 4:
          this.filters.from = moment().subtract(3, 'month').startOf('month').format('YYYY-MM-DD')
          this.filters.to = moment().format('YYYY-MM-DD')
      }
      await this.getTransactions()
    },
    async exportPdf() {
      try {
        this.addPreloader()
        const data = await CompaniesService.getCategoryReportPdf({
          main_pather: this.item.main_pather,
          type: this.item.type,
          from: this.filters.from,
          to: this.filters.to,
          company_id: this.$route.params.id,
          id: this.item.id,
          index: this.item.index,
        })
        if (data.status === 200){
          this.removePreloader()
          window.open(data.data)
        }
      } catch (e) {
        this.removePreloader()
        this.showErrorSwal(e)
      }
    },
    async exportExcel() {
      try {
        this.addPreloader()
        const data = await CompaniesService.exportExcel({
          item: this.item,
          data: this.data,
          from: this.filters.from,
          to: this.filters.to,
        })
        if (data.status === 200) {
          this.removePreloader()
          await this.forceFileDownload(data.data, `TRANSACTIONS_${moment(this.filters.from).format('MM-DD-YYYY')}_${moment(this.filters.to).format('MM-DD-YYYY')}.xlsx`)
        }
      } catch (e) {
        this.removePreloader()
        this.showErrorSwal(e)
      }
    },
  },
}
</script>
<style>
.card2{
  box-shadow: 4px 4px 24px 4px rgb(34,41,47, 0.1) !important;
}
.dark-layout .card2 {
  box-shadow: 0 1px 3px 0 rgb(255 255 255 / 0.1), 0 1px 2px -1px rgb(255 255 255 / 0.1) !important;
  background: #222222 !important;
}
.table-light2{
  background-color: #222222 !important;
}
.table-danger2{
  background-color: #ff959a42 !important;
}
.bg-so-dark{
  color: #363639FF !important;
}
.ml-half-1{
  margin-left: 0.5rem;
}
.mxb{
  margin: 0 1rem;
}
.header-sidebar-report{
  display: grid; grid-template-columns: 1fr 1fr 1fr;
}
@media only screen and (max-width: 760px) {
  .header-sidebar-report{
    display: block !important;
  }
  .ml-half-1{
    margin-left: 0;
    margin-top: 1rem;
  }
  .mxb{
    margin: 1rem 0 0 0;
  }
}
</style>
